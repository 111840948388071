import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpaceSaving = () => (
  <Layout>
    <SEO title="Space Saving Furniture" keyword={'Space saving','furniture','Interior Design'} />
    <center><h2>Space Saving Ideas</h2></center>
  
  <Description/>
  </Layout>
)
function Description(){
   return <p1>Coming soon...</p1>
}

export default SpaceSaving